<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">웹발송</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="source">
        <div class="row card-wrapper">
          <div class="col-lg-3">
            <card class="h-100 mh-100">
              <template slot="header">
                <h3 class="mb-7 d-inline">웹발송</h3>
              </template>
              <div class="form-row mb-1">
                <label class="col-lg-4 col-form-label form-control-label"
                  >발송채널<span class="text-danger">*</span></label
                >
                <div class="col-md-8">
                  <base-input
                    v-model="baseInfo.message_type"
                    name="발송채널"
                    additionalClasses="mb-0"
                  >
                    <el-select
                      class="select-danger"
                      placeholder="선택"
                      filterable
                      v-model="baseInfo.message_type"
                    >
                      <el-option
                        v-for="(option, index) in contractedMessageTypes"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="index"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div v-if="baseInfo.message_type === 'AT'" class="form-row mb-1">
                <label class="col-md-4 col-form-label form-control-label"
                  >템플릿 선택<span class="text-danger">*</span></label
                >
                <div class="col-md-8">
                  <base-input
                    v-model="template.kkoSeqno"
                    name="템플릿"
                    additionalClasses="mb-0"
                  >
                    <el-select
                      v-model="template"
                      @change="changeTemplate"
                      value-key="kkoTemplateCd"
                      class="select-danger"
                      placeholder="선택"
                      filterable
                    >
                      <el-option
                        v-for="(option, index) in templates"
                        class="select-danger"
                        :value="option"
                        :label="option.kkoTemplateCd"
                        :key="index"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div
                v-if="baseInfo.message_type.endsWith('M')"
                class="form-row mb-1"
              >
                <label class="col-md-4 col-form-label form-control-label"
                  >템플릿 선택</label
                >
                <div class="col-md-8">
                  <base-input
                    v-model="smsTemplate.kkoSeqno"
                    name="템플릿"
                    additionalClasses="mb-0"
                  >
                    <el-select
                      v-model="smsTemplate"
                      @change="changeSmsTemplate"
                      value-key="kkoSeqno"
                      class="select-danger"
                      placeholder="선택"
                      filterable
                    >
                      <el-option
                        v-for="(option, index) in smsTemplates"
                        class="select-danger"
                        :value="option"
                        :label="option.templateCd"
                        :key="index"
                      />
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div
                v-if="baseInfo.message_type.endsWith('M')"
                class="form-row mb-2"
              >
                <label class="col-md-4 col-form-label form-control-label"
                  >발신번호<span class="text-danger">*</span></label
                >
                <div class="col-md-8">
                  <!--                  <base-input v-model="baseInfo.sender_no" name="발신번호" additionalClasses="mb-0" v-validate="'required'" :maxlength="11" @keyup="onlyNumber">-->
                  <!--                  </base-input>-->
                  <el-select
                    class="select-danger"
                    placeholder="Single Select"
                    filterable
                    v-model="baseInfo.sender_no"
                    name="senderNo"
                    :error="_getError('senderNo')"
                    v-validate="'required'"
                  >
                    <el-option
                      v-for="(option, index) in senderNos"
                      class="select-danger"
                      :value="option.sendNumber"
                      :label="option.sendNumber"
                      :key="index"
                    />
                  </el-select>
                </div>
              </div>

              <div class="form-row mb-4">
                <div class="col-md-12">
                  <div class="float-xl-right">
                    <!--                    <el-tooltip content="버튼입력" placement="top">-->
                    <!--                      <base-button v-show="baseInfo.message_type === 'FT'" type="primary" size="sm" class="btn-icon-only" @click="showEditButtonsLayer=true">-->
                    <!--                        <i class="fas fa-edit"></i>-->
                    <!--                      </base-button>-->
                    <!--                    </el-tooltip>-->
                    <el-tooltip
                      content="미리보기"
                      placement="top"
                      v-if="
                        baseInfo.message_type &&
                          template.templateEmphasizeType != 'ITEM_LIST'
                      "
                    >
                      <base-button
                        type="secondary"
                        size="sm"
                        class="btn-icon-only"
                        @click="showPreViewLayer = true"
                      >
                        <i class="fas fa-tv"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="메시지 삭제"
                      placement="top"
                      v-if="baseInfo.message_type"
                    >
                      <base-button
                        type="danger"
                        size="sm"
                        class="btn-icon-only"
                        @click="removeMessage"
                      >
                        <i class="fas fa-trash"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>

              <div
                v-show="baseInfo.message_type.endsWith('T')"
                class="form-row"
              >
                <div class="col-md-12">
                  <div class="float-xl-right">
                    <el-tooltip
                      placement="bottom"
                      v-if="template.templateEmphasizeType === 'TEXT'"
                    >
                      <div
                        slot="content"
                        v-html="template.templateSubtitle"
                      ></div>
                      <base-button type="info" size="sm">보조문구</base-button>
                    </el-tooltip>
                  </div>
                  <base-input
                    label="강조 표기 타이틀"
                    v-model="template.templateTitle"
                    v-if="template.templateEmphasizeType === 'TEXT'"
                    disabled
                  ></base-input>
                  <base-input
                    label="템플릿 헤더"
                    v-model="template.templateHeader"
                    v-if="
                      template.templateEmphasizeType === 'ITEM_LIST' &&
                        template.templateHeader != null
                    "
                    disabled
                    input-classes="form-control-sm"
                  ></base-input>
                  <div class="float-xl-right">
                    <el-tooltip
                      placement="bottom"
                      v-if="
                        template.templateMessageType === 'EX' ||
                          template.templateMessageType === 'MI'
                      "
                    >
                      <div slot="content" v-html="template.templateExtra"></div>
                      <base-button type="info" size="sm">부가정보</base-button>
                    </el-tooltip>
                    <el-tooltip
                      placement="bottom"
                      v-if="
                        template.templateMessageType === 'AD' ||
                          template.templateMessageType === 'MI'
                      "
                    >
                      <div slot="content" v-html="template.templateAd"></div>
                      <base-button type="info" size="sm">광고문구</base-button>
                    </el-tooltip>
                  </div>
                  <base-input
                    label="템플릿 이미지"
                    v-if="template.templateEmphasizeType === 'IMAGE'"
                  >
                    <img
                      v-if="template.templateEmphasizeType === 'IMAGE'"
                      :src="`${templateImageData}`"
                      style="width: 100%"
                    />
                  </base-input>
                  <base-input
                    label="템플릿 이미지"
                    v-else-if="
                      template.templateEmphasizeType === 'ITEM_LIST' &&
                        templateImageData
                    "
                  >
                    <img
                      v-if="
                        template.templateEmphasizeType === 'ITEM_LIST' &&
                          templateImageData
                      "
                      :src="`${templateImageData}`"
                      style="width: 100%"
                    />
                  </base-input>
                  <base-input
                    label="아이템 하이라이트"
                    v-if="
                      template.templateEmphasizeType === 'ITEM_LIST' &&
                        itemHighlight.title != null
                    "
                  >
                    <base-input
                      label="타이틀"
                      v-model="itemHighlight.title"
                      v-if="
                        template.templateEmphasizeType === 'ITEM_LIST' &&
                          itemHighlight.title
                      "
                      disabled
                      input-classes="form-control-sm"
                    ></base-input>
                    <base-input
                      label="설명"
                      v-model="itemHighlight.description"
                      v-if="
                        template.templateEmphasizeType === 'ITEM_LIST' &&
                          itemHighlight.description
                      "
                      disabled
                      style="margin-top: -1rem;"
                      input-classes="form-control-sm"
                    ></base-input>
                    <base-input
                      label="썸네일"
                      v-if="
                        template.templateEmphasizeType === 'ITEM_LIST' &&
                          itemHighlight.imageUrl
                      "
                      style="margin-top: -1rem;"
                    >
                      <img
                        v-if="
                          template.templateEmphasizeType === 'ITEM_LIST' &&
                            itemHighlight.imageUrl
                        "
                        :src="`${itemHighlight.imageUrl}`"
                        style="width: 50%"
                      />
                    </base-input>
                  </base-input>
                  <base-input label="메시지">
                    <textarea
                      class="form-control"
                      id="message"
                      rows="8"
                      placeholder="내용 입력"
                      v-model="baseInfo.message"
                      :maxlength="maxLength"
                      :disabled="baseInfo.message_type === 'AT'"
                    />
                    <small class="float-right"
                      >{{ talkLength }} / {{ maxLength }} 자</small
                    >
                  </base-input>
                  <base-button
                    v-show="baseInfo.message_type === 'FT'"
                    type="primary"
                    wide
                    size="sm"
                    class="mb-1"
                    @click="showEditButtonsLayer = true"
                  >
                    <i class="fas fa-edit"></i> 버튼추가
                  </base-button>
                </div>

                <!-- 아이템리스트 영역 -->
                <base-input
                  label="아이템 리스트"
                  v-if="
                    template.templateEmphasizeType === 'ITEM_LIST' &&
                      itemLists.list != null
                  "
                >
                  <el-table
                    :data="itemLists.list"
                    :header-row-class-name="'thead-light'"
                    :header-cell-style="{
                      'font-weight': 'bold',
                      'font-size': '0.6rem'
                    }"
                  >
                    <el-table-column
                      prop="title"
                      label="타이틀"
                      min-width="120px"
                      max-width="150px"
                    ></el-table-column>
                    <el-table-column
                      prop="description"
                      label="설명"
                      min-width="200px"
                      max-width="200px"
                    ></el-table-column>
                  </el-table>
                </base-input>

                <div class="col-md-12 mb-1">
                  <base-input
                    label="아이템 요약정보"
                    v-if="
                      template.templateEmphasizeType === 'ITEM_LIST' &&
                        itemLists.summary.title != null
                    "
                  >
                    <base-input
                      label="타이틀"
                      v-model="itemLists.summary.title"
                      v-if="
                        template.templateEmphasizeType === 'ITEM_LIST' &&
                          itemLists.summary.title != ''
                      "
                      disabled
                      input-classes="form-control-sm"
                    ></base-input>
                    <base-input
                      label="설명"
                      v-model="itemLists.summary.description"
                      v-if="
                        template.templateEmphasizeType === 'ITEM_LIST' &&
                          itemLists.summary.description != ''
                      "
                      disabled
                      style="margin-top: -1rem;"
                      input-classes="form-control-sm"
                    ></base-input>
                  </base-input>
                </div>

                <!-- 알림톡 버튼 view 영역 -->
                <template v-for="option in atButtons" :value="option">
                  <div class="col-md-12 mb-1">
                    <el-tooltip
                      placement="right"
                      v-if="
                        option.type === 'AL' ||
                          option.type === 'WL' ||
                          option.type === 'P1' ||
                          option.type === 'P2' ||
                          option.type === 'P3'
                      "
                    >
                      <div slot="content" v-html="getBtnUrls(option)"></div>
                      <base-button
                        buttonClasses="kakao-button-bg-color"
                        type="Secondary"
                        wide
                        size="sm"
                        >{{ option.name }}</base-button
                      >
                    </el-tooltip>
                    <base-button
                      v-else
                      buttonClasses="kakao-button-bg-color"
                      type="Secondary"
                      wide
                      size="sm"
                      >{{ option.name }}</base-button
                    >
                  </div>
                </template>

                <!-- 친구톡 버튼 view 영역 -->
                <template v-for="option in ftButtons" :value="option">
                  <div class="col-md-12 mb-1">
                    <el-tooltip
                      placement="right"
                      v-if="option.type === 'AL' || option.type === 'WL'"
                    >
                      <div slot="content" v-html="getBtnUrls(option)"></div>
                      <base-button
                        buttonClasses="kakao-button-bg-color"
                        type="Secondary"
                        wide
                        size="sm"
                        >{{ option.name }}</base-button
                      >
                    </el-tooltip>
                    <base-button
                      v-else
                      buttonClasses="kakao-button-bg-color"
                      type="Secondary"
                      wide
                      size="sm"
                      >{{ option.name }}</base-button
                    >
                  </div>
                </template>

                <!-- 바로연결 view 영역 -->
                <template v-for="option in quickReplies" :value="option">
                  <el-tooltip
                    placement="top"
                    v-if="option.type === 'AL' || option.type === 'WL'"
                  >
                    <div slot="content" v-html="getBtnUrls(option)"></div>
                    <base-button
                      buttonClasses="badge badge-pill badge-secondary"
                      size="sm"
                      >{{ option.name }}</base-button
                    >
                  </el-tooltip>
                  <base-button
                    v-else
                    buttonClasses="badge badge-pill badge-secondary"
                    size="sm"
                    >{{ option.name }}</base-button
                  >
                </template>
              </div>

              <div
                v-show="
                  baseInfo.message_type === 'FT' ||
                    baseInfo.message_type === 'MM'
                "
                class="form-row"
              >
                <div
                  v-show="baseInfo.message_type === 'FT'"
                  class="col-md-12 mt-2"
                >
                  <base-input additional-classes="mb-0">
                    <base-radio
                      v-model="ftNewImageYn"
                      class="mt-1 mb-3"
                      name="Y"
                      :inline="true"
                    >
                      새 이미지
                    </base-radio>
                    <base-radio
                      v-model="ftNewImageYn"
                      class="mt-1 mb-3"
                      name="N"
                      :inline="true"
                    >
                      기존 이미지
                    </base-radio>
                  </base-input>

                  <dropzone-file-upload
                    ref="dropzone"
                    :options="dropzoneOptions"
                    acceptedFile="image/*"
                    v-model="image"
                    v-if="ftNewImageYn === 'Y'"
                  />

                  <base-input
                    label="기존 업로드된 이미지 URL"
                    placeholder="https://mud-kage.kakao.com/dn/XyM8u/btq53kfXZfs/IjZp3KGjZgIehi8o8FiE6k/img_l.jpg"
                    v-model="kkoImgUrl"
                    :maxlength="100"
                    additional-classes="mb-1"
                    v-else-if="ftNewImageYn === 'N'"
                  ></base-input>

                  <base-input group additional-classes="mb-1">
                    <base-checkbox :inline="true" v-model="wide"
                      >와이드 이미지</base-checkbox
                    >
                  </base-input>
                  <base-input
                    label="이미지 클릭 시, 이동할 URL"
                    placeholder="https://bizmsg-web.kakaoenterprise.com/user"
                    v-model="kkoImgLinkUrl"
                    :maxlength="100"
                    additional-classes="mb-3 mt-3"
                  ></base-input>
                </div>

                <div
                  v-show="baseInfo.message_type === 'MM'"
                  class="col-md-12 mt-2"
                >
                  <dropzone-file-upload
                    ref="multiDropzone"
                    :maxFiles="3"
                    :options="multiDropzoneOptions"
                    acceptedFile="image/jpg"
                    v-model="images"
                    multiple
                  />
                </div>
              </div>

              <!-- 대체발송 선택 -->
              <div
                v-if="
                  baseInfo.message_type === 'AT' ||
                    baseInfo.message_type === 'FT'
                "
                class="form-row mb-1 mt-2"
              >
                <div class="col-md-12">
                  <base-input group additional-classes="mb-1">
                    <base-checkbox
                      :inline="true"
                      v-model="substitution"
                      :disabled="clientInfo.substitutionYn !== 'Y'"
                    >
                      대체발송
                      <span
                        v-if="clientInfo.substitutionYn !== 'Y'"
                        class="ml-2 substitution-text text-danger"
                        >계약이 필요한 옵션입니다.</span
                      >
                    </base-checkbox>
                    <button-radio-group
                      v-show="substitution"
                      :options="smsTypes"
                      button-classes="btn-sm"
                      v-model="baseInfo.sms_type"
                      @change="limitSmsMessage"
                    />
                  </base-input>
                </div>
              </div>
              <div
                v-if="substitution && baseInfo.message_type.endsWith('T')"
                class="form-row mb-2"
              >
                <label class="col-md-4 col-form-label form-control-label"
                  >발신번호<span class="text-danger">*</span></label
                >
                <div class="col-md-8">
                  <el-select
                    class="select-danger"
                    placeholder="Single Select"
                    filterable
                    v-model="baseInfo.sender_no"
                    name="senderNo"
                    :error="_getError('senderNo')"
                    v-validate="'required'"
                  >
                    <el-option
                      v-for="(option, index) in senderNos"
                      class="select-danger"
                      :value="option.sendNumber"
                      :label="option.sendNumber"
                      :key="index"
                    />
                  </el-select>
                </div>
              </div>
              <div
                class="form-row mb-1"
                v-if="
                  baseInfo.message_type.endsWith('M')
                    ? ['LM', 'MM'].includes(baseInfo.message_type)
                    : substitution && ['LM', 'MM'].includes(baseInfo.sms_type)
                "
              >
                <div class="col-md-12">
                  <base-input
                    placeholder="제목입력 (LMS 발송시 사용)"
                    v-model="baseInfo.title"
                    :maxlength="20"
                    additional-classes="mb-0"
                  ></base-input>
                </div>
              </div>
              <div
                v-show="substitution || baseInfo.message_type.endsWith('M')"
                class="form-row mb-1"
              >
                <div class="col-md-12">
                  <base-input additional-classes="mb-0">
                    <textarea
                      class="form-control"
                      id="smsMessage"
                      rows="8"
                      placeholder="내용 입력"
                      v-model="baseInfo.sms_message"
                      :maxlength="smsMaxLength"
                    />
                  </base-input>
                </div>
              </div>

              <div
                v-show="substitution || baseInfo.message_type.endsWith('M')"
                class="form-row mb-1"
              >
                <div class="col-md-6" style="bottom:4px;">
                  <base-checkbox :inline="true" v-model="ad"
                    >광고여부</base-checkbox
                  >
                </div>
                <div class="col-md-6">
                  <small class="float-right"
                    >{{ smsLength }} / {{ smsMaxLength }} byte</small
                  >
                </div>
              </div>

              <!-- 발송 시간 및 액션 영역 -->
              <div v-if="baseInfo.message_type">
                <div class="form-row mb-1">
                  <div class="col-md-12">
                    <base-radio
                      name="I"
                      :inline="true"
                      class="mb-1"
                      v-model="reservedCd"
                      >즉시발송</base-radio
                    >
                    <base-radio
                      name="R"
                      :inline="true"
                      class="mb-1"
                      v-model="reservedCd"
                      >예약발송</base-radio
                    >
                  </div>
                </div>
                <div class="form-row" v-if="reservedCd === 'R'">
                  <div class="col-md-12">
                    <base-input
                      type="datetime-local"
                      id="reservation-date-input"
                      name="예약일시"
                      v-validate="'reservation_date|after_date_time'"
                      v-model="reservationDate"
                      :error="_getError('예약일시')"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md-12">
                    <base-button
                      block
                      size="lg"
                      type="primary"
                      @click="preSendProcess"
                      >발송</base-button
                    >
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-lg-9">
            <card class="h-100 mh-100" headerClasses="pt-3 pb-1">
              <template slot="header">
                <div class="float-xl-left">
                  <div class="form-row mb-0">
                    <small class="text-danger font-weight-bold"
                      >- 파이프( | )를 이용하여 변수와 변수값을 구분해주세요.
                      ex) 변수1|변수2|변수3...</small
                    >
                  </div>
                  <div class="form-row mb-3">
                    <small class="text-danger font-weight-bold"
                      >- 최대 5만 건 등록 가능 (대량발송은 상위 10건만
                      표기됩니다.)</small
                    >
                  </div>
                </div>

                <div class="float-xl-right">
                  <base-input group additionalClasses="mb-0">
                    <el-tooltip content="초기화" placement="top"
                      ><base-button
                        type="default"
                        size="sm"
                        class="mb-1 mr-2"
                        @click="initData"
                        ><i class="fa fa-sync-alt"></i></base-button
                    ></el-tooltip>
                    <base-button
                      type="primary"
                      size="sm"
                      class="mb-1 mr-2"
                      @click="download"
                      >수신자 등록 양식</base-button
                    >
                    <file-input
                      ref="inputExcel"
                      initialLabel="엑셀 파일 업로드"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      labelClasses="small"
                      @change="excelChange"
                    />
                  </base-input>
                </div>
              </template>

              <div class="form-row mb-3">
                <div class="col-md-8">
                  총
                  <span class="text-danger font-weight-bold">{{
                    this.getTotalReceiversLength()
                  }}</span>
                  건
                </div>
                <div class="col-md-4">
                  <div class="float-xl-right">
                    <base-button
                      type="danger"
                      size="sm"
                      class="ml-2"
                      @click="
                        receivers = [];
                        excelReceivers = [];
                      "
                      :disabled="existExcels"
                      >전체삭제</base-button
                    >
                    <base-button
                      type="danger"
                      size="sm"
                      @click="removeReceivers"
                      :disabled="existExcels"
                      >선택삭제</base-button
                    >
                    <base-button
                      type="primary"
                      size="sm"
                      @click="addReceiver(null)"
                      :disabled="existExcels"
                      >추가</base-button
                    >
                  </div>
                </div>
              </div>

              <div>
                <div
                  ref="receiversDiv"
                  class="form-row mb-2 h-100 mh-100"
                  v-resize.initial="receiversTableResize"
                >
                  <el-table
                    infinite-scroll-disabled="onload"
                    infinite-scroll-distance="10"
                    header-row-class-name="thead-light"
                    header-cell-class-name="p-2"
                    cell-class-name="p-2"
                    :data="receivers"
                    :height="receiversTableHeight"
                    :max-height="receiversTableHeight"
                    @selection-change="selectionChange"
                    empty-text="수신자를 추가해 주세요."
                    border
                  >
                    <el-table-column
                      type="selection"
                      label="선택"
                      prop="checked"
                      align="left"
                      :resizable="false"
                      fixed
                      :disabled="existExcels"
                    />
                    <el-table-column
                      label="수신번호"
                      prop="receiverNo"
                      min-width="100"
                      align="center"
                      sortable
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item
                            :error="_getError(`수신번호${scope.$index + 1}`)"
                          >
                            <el-input
                              :autofocus="true"
                              :name="`수신번호${scope.$index + 1}`"
                              size="small"
                              v-model="scope.row.receiverNo"
                              :autosize="true"
                              v-validate="'required|numeric|min:8|max:12'"
                              placeholder="숫지만 입력가능"
                              :disabled="existExcels"
                            ></el-input>
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="메시지 변수"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.headers"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="메시지 변수값"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.values"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="버튼 변수"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.btnHeaders"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="버튼 변수값"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.btnValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="강조 변수"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.titleHeaders"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="강조 변수값"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.titleValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="바로연결 변수"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.quickReplyHeaders"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="바로연결 변수값"
                      min-width="100"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.quickReplyValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="템플릿헤더 변수"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.templateHeaders"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="템플릿헤더 변수값"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.templateHeaderValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="아이템 리스트 설명 변수"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.itemDescriptions"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="아이템 리스트 설명 변수값"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.itemDescriptionValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="아이템 요약정보 설명 변수"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.descriptions"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="아이템 요약정보 설명 변수값"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.descriptionValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="아이템 하이라이트 타이틀 변수"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.itemHighlightTitles"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="아이템 하이라이트 타이틀 변수값"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.itemHighlightTitleValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="아이템 하이라이트 설명 변수"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.itemHighlightDescriptions"
                              :autosize="true"
                              placeholder="header|header..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="아이템 하이라이트 설명 변수값"
                      min-width="120"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-input
                              size="small"
                              v-model="scope.row.itemHighlightDescriptionValues"
                              :autosize="true"
                              placeholder="value|value..."
                              :disabled="existExcels"
                            />
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="삭제"
                      min-width="40"
                      :resizable="false"
                      fixed="right"
                      header-align="center"
                      align="center"
                      :disabled="existExcels"
                    >
                      <template slot-scope="scope">
                        <el-form>
                          <el-form-item>
                            <el-tooltip content="삭제" placement="top">
                              <base-button
                                type="danger"
                                class="btn-icon-only btn-sm"
                                @click="removeReceiver(scope.$index)"
                                :disabled="existExcels"
                              >
                                <i class="ni ni-fat-delete"></i>
                              </base-button>
                            </el-tooltip>
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>

    <!-- 미리보기 레이어 -->
    <pre-view-layer
      v-if="showPreViewLayer"
      :baseInfo="baseInfo"
      :template="template"
      :atButtons="atButtons"
      :ftButtons="ftButtons"
      :quickReplies="quickReplies"
      :templateImageData="templateImageData"
      :image="image"
      :images="images"
      :receiver="receivers[0]"
      :wide="wide"
      @close="showPreViewLayer = false"
    />

    <!-- 친구톡 버튼등록 레이어 -->
    <edit-buttons-layer
      v-if="showEditButtonsLayer"
      :baseInfo="baseInfo"
      :buttons="ftButtons"
      @close="showEditButtonsLayer = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Form,
  FormItem,
  Loading
} from "element-ui";
import FileInput from "@/shared/components/Inputs/FileInput";
import DropzoneFileUpload from "@/shared/components/Inputs/DropzoneFileUpload";
import commJsUtilMixin from "@/shared/mixins/commJsUtil";
import commValidatorMixin from "@/shared/mixins/commValidatorMixin";
import resize from "vue-resize-directive";
import {
  USER_API_PREFIX,
  VUE_REST_API_URL,
  REST_API_PREFIX,
  MESSAGE_TYPES,
  RESULT_CODE
} from "@/shared/util/const";
import PreViewLayer from "./PreViewLayer";
import EditButtonsLayer from "./EditButtonsLayer";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import swal from "sweetalert2";

const XLSX = require("xlsx");

export default {
  name: "Send",
  inject: ["$validator"],
  mixins: [commJsUtilMixin, commValidatorMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    FileInput,
    DropzoneFileUpload,
    PreViewLayer,
    EditButtonsLayer
  },
  directives: {
    resize,
    "el-table-infinite-scroll": elTableInfiniteScroll
  },
  data() {
    return {
      clientInfo: {},
      messageTypes: MESSAGE_TYPES,
      templateCode: "",
      maxLength: 1000,
      templates: [],
      smsTemplates: [],
      smsTemplate: { kkoSeqno: "" },
      senderNos: [],
      smsTypes: [],
      template: {
        kkoSeqno: "",
        templateMessageType: "",
        templateAd: "",
        templateExtra: "",
        templateEmphasizeType: "",
        templateTitle: "",
        templateSubtitle: "",
        kkoTemplateCd: "",
        templateContent: "",
        buttonsJson: "",
        quickRepliesJson: "",
        templateHeader: "",
        templateItemHighlightJson: "",
        templateItemJson: ""
      },
      baseInfo: {
        message_type: "",
        template_code: "",
        sms_type: null,
        sender_key: "",
        sender_no: "",
        message: "",
        sms_message: "",
        additional_info: {
          substitutionYn: "",
          smsYn: "",
          lmsYn: "",
          mmsYn: ""
        }
      },
      atButtons: [],
      quickReplies: [],
      itemHighlight: {},
      itemLists: {},
      ftButtons: [],
      templateImageData: null,
      sendInfo: {},
      substitution: false,
      reservedCd: "I",
      wide: false,
      ad: false,
      showPreViewLayer: false,
      showEditButtonsLayer: false,
      onload: false,
      reservationDate: null,
      receivers: [],
      excelReceivers: [],
      image: [],
      images: [],
      imgLink: null,
      selectedReceivers: [],
      receiversTableHeight: 600,
      dropzoneOptions: {},
      multiDropzoneOptions: {},
      token: {},
      excels: [],
      maxReceiver: 50000,
      ftNewImageYn: "Y",
      kkoImgLinkUrl: null,
      kkoImgUrl: null
    };
  },
  created() {
    // Clinet 및 채널 정보 조회
    this.selectClientInfo();
    // 템플릿 정보 조회
    this.selectTemplates();
    // 발신자 목록 조회
    this.selectSenderNos();
    // 파일 컴포넌트 설정
    this.setDropzoneOptions();
    // 데이터 검증 설정
    this.customValidator();
  },
  methods: {
    /**
     * Vee-Validator 커스텀
     */
    customValidator() {
      this.$validator.extend("after_date_time", {
        getMessage: field => field + "는 현재시간에서 10분 이후여야 합니다.",
        validate: value =>
          new Date(this.getDateTwoDigit(1, 10)).getTime() <
          new Date(value).getTime()
      });

      this.$validator.extend("reservation_date", {
        getMessage: field => field + "는 한달까지 가능합니다.",
        validate: value =>
          new Date(value).getTime() <
          new Date(this.getDateTwoDigit(2)).getTime()
      });
    },

    /**
     * 고객 정보 조회
     */
    selectClientInfo() {
      this.$axios.get(`${USER_API_PREFIX}/send/clientInfo`).then(res => {
        this.clientInfo = res.data;
        this.baseInfo.additional_info = {
          channelId: this.clientInfo.channelId,
          substitutionYn: this.clientInfo.substitutionYn,
          smsYn: this.clientInfo.smsYn,
          lmsYn: this.clientInfo.lmsYn,
          mmsYn: this.clientInfo.mmsYn
        };
        if (this.clientInfo.smsYn === "Y")
          this.smsTypes.push({ label: "SMS", value: "SM" }); // sms 대체발송 세팅
        if (this.clientInfo.lmsYn === "Y")
          this.smsTypes.push({ label: "LMS", value: "LM" }); // lms 대체발송 세팅
      });
    },

    /**
     * 템플릿 목록 조회
     */
    selectTemplates() {
      this.$axios
        .get(`${USER_API_PREFIX}/template/templatesByApr`)
        .then(res => {
          this.templates = res.data.result.templates;
          if (this.templates.length > 0) {
            this.template = this.templates[0];
            this.baseInfo.template_code = this.template.kkoTemplateCd;
            this.baseInfo.message = this.template.templateContent;
          }
        });
    },

    /**
     * Sms 템플릿 목록 조회
     */
    selectSmsTemplates() {
      this.baseInfo.message = null;
      this.$axios
        .get(`${USER_API_PREFIX}/smsTemplate/list`, {
          params: {
            sendType: this.baseInfo.message_type
          }
        })
        .then(res => {
          this.smsTemplates = res.data;
          this.smsTemplates.unshift({
            templateCd: "선택",
            kkoSeqno: "SELECT",
            contentTitle: "",
            templateContent: ""
          });
          this.smsTemplate = this.smsTemplates[0];
          this.limitSmsMessage();
        });
    },

    /**
     * 발신자 목록 조회
     */
    selectSenderNos() {
      this.$axios.get(`${USER_API_PREFIX}/sender/web-send-nums`).then(res => {
        this.senderNos = res.data.result.webSendNums;
        this.baseInfo.sender_no =
          this.senderNos.length > 0 && this.senderNos[0]
            ? this.senderNos[0].sendNumber
            : "";
      });
    },

    /**
     * 템플릿 변경 이벤트
     */
    changeTemplate() {
      this.baseInfo.template_code = this.template.kkoTemplateCd;
      this.baseInfo.message = this.template.templateContent;
    },

    /**
     * SMS 템플릿 변경 이벤트
     */
    changeSmsTemplate() {
      this.baseInfo.title = this.smsTemplate.contentTitle;
      this.baseInfo.sms_message = this.smsTemplate.templateContent;
    },

    /**
     * 엑셀 파일 변경 이벤트
     */
    excelChange(files) {
      this.initReceiver();
      this.excels = files;

      if (!this.existExcels) return false;

      swal.fire({
        title: "File Loading...",
        onBeforeOpen: () => {
          swal.showLoading();
        },
        onOpen: setTimeout(() => {
          const file = files[0];
          const reader = new FileReader();

          reader.onload = e => {
            const data = new Uint8Array(e.target.result);
            const excel = XLSX.read(data, { type: "array" });
            outLoop: for (
              let i = 0, length = excel.SheetNames.length;
              i < length;
              i++
            ) {
              const rows = XLSX.utils.sheet_to_json(
                excel.Sheets[excel.SheetNames[i]],
                { header: 2 }
              );
              for (let j = 0, jLength = rows.length; j < jLength; j++) {
                if (this.getTotalReceiversLength() >= this.maxReceiver)
                  break outLoop;

                this.excelReceivers.push({
                  receiverNo: rows[j]["수신번호"],
                  headers: rows[j]["메시지 변수"],
                  values: rows[j]["메시지 변수값"],
                  btnHeaders: rows[j]["버튼 변수"],
                  btnValues: rows[j]["버튼 변수값"],
                  titleHeaders: rows[j]["강조 변수"],
                  titleValues: rows[j]["강조 변수값"],
                  quickReplyHeaders: rows[j]["바로연결 변수"],
                  quickReplyValues: rows[j]["바로연결 변수값"],
                  templateHeaders: rows[j]["템플릿헤더 변수"],
                  templateHeaderValues: rows[j]["템플릿헤더 변수값"],
                  itemDescriptions: rows[j]["아이템 리스트 설명 변수"],
                  itemDescriptionValues: rows[j]["아이템 리스트 설명 변수값"],
                  descriptions: rows[j]["아이템 요약정보 설명 변수"],
                  descriptionValues: rows[j]["아이템 요약정보 설명 변수값"],
                  itemHighlightTitles: rows[j]["아이템 하이라이트 타이틀 변수"],
                  itemHighlightTitleValues:
                    rows[j]["아이템 하이라이트 타이틀 변수값"],
                  itemHighlightDescriptions:
                    rows[j]["아이템 하이라이트 설명 변수"],
                  itemHighlightDescriptionValues:
                    rows[j]["아이템 하이라이트 설명 변수값"]
                });
              }
            }
            this.shiftExcelReceivers();
            this.$refs.inputExcel.$refs.inputFile.value = null;
            swal.close();
          };
          reader.readAsArrayBuffer(file);
        }, 500)
      });
    },

    shiftExcelReceivers() {
      for (
        let i = 0,
          length =
            this.excelReceivers.length > 10 ? 10 : this.excelReceivers.length;
        i < length;
        i++
      ) {
        this.addReceiver(this.excelReceivers.shift());
      }
    },

    /**
     * 엑셀 파일 (양식) 다운로드
     */
    download() {
      this.$axios
        .get(`${USER_API_PREFIX}/send/templateDown`, {
          responseType: "blob"
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            res.headers["content-disposition"].replace(
              "attachment; filename=",
              ""
            )
          );
          link.click();
          window.URL.revokeObjectURL(url);
        });
    },

    /**
     * 수신자 추가
     * param receiver
     */
    addReceiver(receiver) {
      if (this.getTotalReceiversLength() < this.maxReceiver) {
        this.receivers.unshift(
          receiver
            ? receiver
            : {
                receiverNo: "",
                headers: "",
                values: "",
                btnHeaders: "",
                btnValues: "",
                titleHeaders: "",
                titleValues: "",
                quickReplyHeaders: "",
                quickReplyValues: "",
                itemDescriptions: "",
                itemDescriptionValues: "",
                descriptions: "",
                descriptionValues: "",
                itemHighlightTitles: "",
                itemHighlightTitleValues: "",
                itemHighlightDescriptions: "",
                itemHighlightDescriptionValues: ""
              }
        );
      }
    },

    /**
     * 수신자 삭제
     * param index
     */
    removeReceiver(index) {
      this.receivers.splice(index, 1);
    },

    /**
     * 수신자 리스트 삭제
     */
    removeReceivers() {
      this.selectedReceivers.forEach(item =>
        this.removeReceiver(this.receivers.indexOf(item))
      );
    },

    /**
     * 수신자 선택 이벤트
     * @param selectedReceivers
     */
    selectionChange(selectedReceivers) {
      this.selectedReceivers = selectedReceivers;
    },

    /**
     * 수신자 테이블 가변 설정
     * 커스텀 필요
     */
    receiversTableResize() {
      this.receiversTableHeight = this.$refs.receiversDiv.offsetHeight;
    },

    /**
     * 이미지 파일 검증
     */
    setDropzoneOptions() {
      const $this = this;
      this.dropzoneOptions = {
        autoProcessQueue: false,
        accept: (file, done) => {
          const fileReader = new FileReader();
          const image = new Image();
          let maxFileSize = $this.wide ? 2 * 1024 * 1024 : 500 * 1024;
          let minWidth = $this.wide ? 0 : 500;

          fileReader.readAsDataURL(file);

          if (file.size > maxFileSize) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify("warning", "이미지 용량이 초과 되었습니다.");
            return false;
          }

          fileReader.onloadend = () => {
            if (fileReader.result) {
              image.onload = () => {
                let maxWidth = $this.wide ? 800 : image.height * 4;
                if (image.width < minWidth && image.width > maxWidth) {
                  $this.$refs.dropzone.dropzone.removeFile(file);
                  $this.notify("warning", "이미지 해상도가 부적합합니다.");
                } else {
                  done();
                }
              };
              image.src = fileReader.result;
            }
          };
        },
        error: function(file) {
          if (!file.accepted) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify("warning", "파일 형식이 올바르지 않습니다.");
          }
        }
      };

      this.multiDropzoneOptions = {
        autoProcessQueue: false,
        accept: function(file, done) {
          let maxFileSize = 200 * 1024;
          if (file.size > maxFileSize) {
            $this.$refs.multiDropzone.dropzone.removeFile(file);
            $this.notify("warning", "파일 용량이 초과 되었습니다.");
          } else {
            done();
          }
        },
        error: function(file) {
          if (!file.accepted) {
            $this.$refs.multiDropzone.dropzone.removeFile(file);
            $this.notify("warning", "파일 형식이 올바르지 않습니다.");
          }
        }
      };
    },

    /**
     * 헤더 맵퍼
     * 메시지를 수신자별 정의로 변경
     * @param message, headersStr, valuesStr
     */
    headerMapper(message, headersStr, valuesStr) {
      let result = message;
      let varArray = result ? result.match(/#\{(.*?)\}/g) : null;
      if (result && varArray && headersStr && valuesStr) {
        const headers = headersStr.split("|");
        const values = valuesStr.split("|");

        if (headers.length === values.length) {
          for (let i = 0, length = headers.length; i < length; i++) {
            if (headers[i].trim() == "") {
              throw "변수명을 입력해주세요.";
            }

            let msgVar = "#{" + headers[i].trim() + "}";
            if (result.indexOf(msgVar) !== -1) {
              result = result.replace(msgVar, values[i].trim());
              varArray.splice(varArray.indexOf(msgVar), 1);
            } else {
              continue;
            }
          }
        } else {
          throw "입력된 변수와 변수값의 갯수가 일치하지 않습니다.";
        }
      }
      console.log("입력되지 않은 변수: {}", varArray);

      if (varArray && varArray.length > 0) {
        throw varArray + " 등 변수가 입력되지 않았습니다.";
      }
      return result;
    },
    /**
     * 발송
     */
    send(webSendMessage) {
      let formData = new FormData();
      formData.append(
        "agentMsgListJson",
        JSON.stringify(webSendMessage.agentMsgList)
      );
      formData.append("msgType", this.sendInfo.message_type);
      formData.append("reservedCd", this.reservedCd);

      if (this.sendInfo.message_type === "MM" && this.images.length > 0) {
        for (let i = 0, length = this.images.length; i < length; i++) {
          formData.append("images", this.images[i]);
        }
      } else if (this.sendInfo.message_type === "FT" && this.image.length > 0) {
        formData.append("image", this.image[0]);
      }

      this._swalQuestion({ title: "발송 하시겠습니까?" }, () => {
        this.$axios
          .post(
            this.sendInfo.message_type === "FT"
              ? `${USER_API_PREFIX}/send/sendMessageFT`
              : `${USER_API_PREFIX}/send/sendMessage`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              swal({
                title: `[웹발송]`,
                text: `웹발송 ${
                  webSendMessage.agentMsgList.length
                } 건 등록 완료되었습니다.`,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-success",
                type: "success"
              });
              // 수신자 데이터 초기화 진행
              this.initData();
            } else {
              this.notify("warning", res.data.result.message);
            }
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    /**
     * 발송 전 정보 설정
     */
    sendProcess() {
      // 발송 정보 세팅
      this.sendInfo = {
        ...this.baseInfo,
        sms_type: this.baseInfo.message_type.endsWith("M")
          ? this.baseInfo.message_type
          : this.baseInfo.sms_type
      };

      let errorMsg;
      let isSuccess = true;
      if (this.maxLength < this.baseInfo.message.length) {
        errorMsg = "메시지 내용은 1000자로 제한됩니다.";
        if (this.baseInfo.message_type === "FT") {
          if (this.image.length > 0)
            errorMsg = "이미지 타입 친구톡의 메시지 내용은 400자로 제한됩니다.";
          if (this.wide)
            errorMsg =
              "와이드 이미지(말풍선) 타입 친구톡의 메시지 내용은 76자로 제한됩니다.";
        }
        isSuccess = false;
      } else if (
        (this.baseInfo.message_type.endsWith("M") &&
          this.baseInfo.sms_message === "") ||
        (this.baseInfo.message_type.endsWith("T") &&
          this.baseInfo.message === "")
      ) {
        errorMsg = "메시지 내용을 입력해주세요.";
        isSuccess = false;
      }
      if (
        this.baseInfo.message_type === "FT" &&
        this.wide &&
        this.ftButtons.length > 1
      ) {
        errorMsg =
          "와이드 이미지(말풍선) 타입 친구톡의 버튼은 1개로 제한됩니다.";
        isSuccess = false;
      }
      if (
        (this.baseInfo.message_type === "FT" ||
          this.baseInfo.message_type === "AT") &&
        this.substitution
      ) {
        if (this.baseInfo.sms_message === "") {
          errorMsg = "부달 메시지 내용을 입력해주세요.";
          isSuccess = false;
        }
      }
      if (!isSuccess) {
        this.notify("warning", `${errorMsg}`);
        return;
      }
      if (this.checkValidateButtonInfo()) {
        // 친구톡 버튼에 대하여 유효성 체크
        return;
      }

      swal.fire({
        title: "메시지 발송 준비중...",
        onBeforeOpen: () => {
          swal.showLoading();
        },
        onOpen: setTimeout(() => {
          let receivers = this.receivers.concat(this.excelReceivers);
          try {
            let webSendMessage = {
              agentMsgList: receivers.map(item => {
                let sendMessage = {
                  senderNo: this.sendInfo.sender_no,
                  mobileNo: item.receiverNo,
                  kkoTemplateCd: this.sendInfo.template_code,
                  kkoMsg: this.headerMapper(
                    this.sendInfo.message,
                    String(item.headers),
                    String(item.values)
                  ),
                  phoneMsg: this.headerMapper(
                    this.sendInfo.sms_message,
                    String(item.headers),
                    String(item.values)
                  ),
                  title: this.sendInfo.title,
                  reservedCd: this.reservedCd,
                  reserveDate:
                    this.reservedCd === "R"
                      ? this.reservationDate.replace("T", " ")
                      : null
                };

                if (this.sendInfo.message_type === "AT") {
                  if (!this.sendInfo.sms_type) {
                    sendMessage.msgType = "KX";
                  } else if (this.sendInfo.sms_type === "SM") {
                    sendMessage.msgType = "KS";
                  } else if (this.sendInfo.sms_type === "LM") {
                    sendMessage.msgType = "KM";
                  } else if (this.sendInfo.sms_type === "MM") {
                    sendMessage.msgType = "KM";
                  }

                  if (this.template.templateEmphasizeType === "ITEM_LIST") {
                    // 아이템리스트형
                    sendMessage.header = this.headerMapper(
                      this.template.templateHeader,
                      String(item.templateHeaders),
                      String(item.templateHeaderValues)
                    );

                    if (this.itemLists != null) {
                      let newItemListInfo = {};
                      // 아이템 리스트
                      if (!this.isEmptyObject(this.itemLists.list)) {
                        newItemListInfo.list = JSON.parse(
                          this.headerMapper(
                            JSON.stringify(this.itemLists.list),
                            String(item.itemDescriptions),
                            this.escapeValues(
                              String(item.itemDescriptionValues)
                            )
                          )
                        );
                      }
                      // 아이템 요약정보
                      if (!this.isEmptyObject(this.itemLists.summary)) {
                        let summary = JSON.parse(
                          this.headerMapper(
                            JSON.stringify(this.itemLists.summary),
                            String(item.descriptions),
                            String(item.descriptionValues)
                          )
                        );
                        if (!this.isEmptyObject(summary)) {
                          newItemListInfo.summary = summary;
                        }
                      }
                      sendMessage.item = JSON.stringify(newItemListInfo);
                    }

                    // 아이템 하이라이트
                    if (!this.isEmptyObject(this.itemHighlight)) {
                      let itemHighlightNoimage = {};
                      itemHighlightNoimage.title = JSON.parse(
                        this.headerMapper(
                          JSON.stringify(this.itemHighlight.title),
                          String(item.itemHighlightTitles),
                          this.escapeValues(
                            String(item.itemHighlightTitleValues)
                          )
                        )
                      );
                      itemHighlightNoimage.description = JSON.parse(
                        this.headerMapper(
                          JSON.stringify(this.itemHighlight.description),
                          String(item.itemHighlightDescriptions),
                          this.escapeValues(
                            String(item.itemHighlightDescriptionValues)
                          )
                        )
                      );
                      sendMessage.itemHighlight = JSON.stringify(
                        itemHighlightNoimage
                      );
                    }
                  }

                  sendMessage.kkoTemplateType = "AT";
                  if (this.template.templateEmphasizeType === "TEXT") {
                    // 강조 타이틀 세팅
                    sendMessage.emphasizeTitle = this.headerMapper(
                      this.template.templateTitle,
                      String(item.titleHeaders),
                      String(item.titleValues)
                    );
                  } else if (
                    this.template.templateEmphasizeType === "IMAGE" &&
                    this.template.templateImageUrl
                  ) {
                    // 이미지형인 경우, 템플릿 유형을 'AI'로 세팅
                    sendMessage.kkoTemplateType = "AI";
                  }

                  // 버튼 세팅
                  let buttons = this.getButton(
                    this.headerMapper(
                      this.template.buttonsJson,
                      String(item.btnHeaders),
                      String(item.btnValues)
                    )
                  );
                  for (let i = 0; i < buttons.length; i++) {
                    sendMessage[`kkoBtnLink${i + 1}`] = buttons[i]
                      ? JSON.stringify(buttons[i])
                      : null;
                  }
                  // 바로연결 세팅
                  let replies = this.getButton(
                    this.headerMapper(
                      this.template.quickRepliesJson,
                      String(item.quickReplyHeaders),
                      String(item.quickReplyValues)
                    )
                  );
                  for (let i = 0; i < replies.length; i++) {
                    sendMessage[`kkoQuickReply${i + 1}`] = replies[i]
                      ? JSON.stringify(replies[i])
                      : null;
                  }
                } else if (this.sendInfo.message_type === "FT") {
                  if (!this.sendInfo.sms_type) {
                    sendMessage.msgType = "FX";
                  } else if (this.sendInfo.sms_type === "SM") {
                    sendMessage.msgType = "FS";
                  } else if (this.sendInfo.sms_type === "LM") {
                    sendMessage.msgType = "FM";
                  } else if (this.sendInfo.sms_type === "MM") {
                    sendMessage.msgType = "FM";
                  }

                  sendMessage.kkoImgWideCd = this.wide ? "Y" : "N";
                  sendMessage.kkoImgLinkUrl = this.kkoImgLinkUrl;
                  sendMessage.kkoImgUrl = this.kkoImgUrl;

                  // 버튼 세팅
                  for (let i = 0; i < this.ftButtons.length; i++) {
                    let ftButton = this.ftButtons[i];
                    //빈값 제거
                    Object.keys(ftButton).forEach(
                      key => ftButton[key] === "" && delete ftButton[key]
                    );
                    sendMessage[`kkoBtnLink${i + 1}`] = ftButton
                      ? this.headerMapper(
                          JSON.stringify(ftButton),
                          String(item.btnHeaders),
                          String(item.btnValues)
                        )
                      : null;
                  }
                } else if (this.sendInfo.message_type === "SM") {
                  sendMessage.msgType = "XS";
                } else if (this.sendInfo.message_type === "LM") {
                  sendMessage.msgType = "XM";
                } else if (this.sendInfo.message_type === "MM") {
                  sendMessage.msgType = "XM";
                }

                return sendMessage;
              })
            };
            // console.log(webSendMessage);

            this.send(webSendMessage); // 메시지 발송 진행
          } catch (e) {
            this.notify("warning", e);
            swal.close();
          }
        }, 500)
      });
    },
    /**
     * 발송 정보 검증
     */
    preSendProcess() {
      if (!this.baseInfo.message_type) {
        this.notify("warning", "발송채널을 선택해주세요.");
        return;
      }

      if (this.receivers.length < 1) {
        this.notify("warning", "수신자 정보가 없습니다.");
      } else {
        this._validateBeforeSubmit(this.sendProcess, () => {
          this.notify("warning", this.$validator.errors.all()[0]);
        });
      }
    },
    /**
     * 빈 객체인지 체크
     */
    isEmptyObject(param) {
      return Object.keys(param).length === 0 && param.constructor === Object;
    },
    /**
     * json parsing 오류 방지를 위한 특수문자 체크
     */
    escapeValues(param) {
      return param.replace(/"/gi, '\\"');
    },
    /**
     * 친구톡 버튼 정보의 유효성 체크
     */
    checkValidateButtonInfo() {
      let isError = false;
      if (this.ftButtons.length > 0) {
        this.ftButtons.some((button, idx) => {
          if (button.type === "") {
            swal({
              title: `경고`,
              text: `버튼(${idx + 1}) 타입을 선택해주세요.`,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              type: "warning",
              width: 400
            });
            isError = true;
          } else if (button.name === "") {
            swal({
              title: `경고`,
              text: `버튼(${idx + 1}) 이름을 입력해주세요.`,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              type: "warning",
              width: 400
            });
            isError = true;
          } else if (button.type === "WL") {
            if (!button.url_mobile) {
              swal({
                title: `경고`,
                text: `버튼(${idx +
                  1}) 웹링크의 Mobile 항목은 필수 정보입니다.`,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                type: "warning",
                width: 400
              });
              isError = true;
            }
          } else if (button.type === "AL") {
            if (
              (!button.scheme_android && !button.scheme_ios) ||
              (!button.scheme_android && !button.url_mobile) ||
              (!button.scheme_ios && !button.url_mobile)
            ) {
              swal({
                title: `경고`,
                text: `버튼(${idx +
                  1}) 앱링크는 Android, iOS, Mobile 중 2가지 이상 필수 입력입니다.`,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                type: "warning",
                width: 400
              });
              isError = true;
            }
          }
          return isError;
        });
      }
      return isError;
    },
    /**
     * 부달 sms 체크
     */
    limitSmsMessage() {
      if (this.smsLength >= this.smsMaxLength)
        this.baseInfo.sms_message = this._maxLengthTextEucKr(
          this.baseInfo.sms_message,
          this.smsMaxLength
        );
    },

    /**
     * 발송 수신자 데이터 초기화
     */
    initData() {
      this.initExcel();
      this.initReceiver();
    },

    /**
     * 수신자 초기화
     */
    initReceiver() {
      this.receivers = [];
      this.excelReceivers = [];
    },

    /**
     * 엑셀 파일 초기화
     */
    initExcel() {
      this.excels = [];
      this.$refs.inputExcel.files = [];
      this.$refs.inputExcel.$refs.inputFile.value = null;
    },

    removeMessage() {
      this.baseInfo.title = null;
      this.baseInfo.message =
        this.baseInfo.message_type === "AT"
          ? this.template.templateContent
          : "";
      this.baseInfo.sms_message = "";
    },

    /**
     * 버튼 정보 JSON -> 배열 형태로 변경하여 반환
     */
    getButton(buttonJson) {
      const templateButtons = JSON.parse(buttonJson);
      let atButtons = [];
      if (templateButtons && templateButtons.length > 0) {
        for (let i = 0, length = templateButtons.length; i < length; i++) {
          atButtons.push({
            name: templateButtons[i].name,
            type: templateButtons[i].linkType,
            scheme_android: templateButtons[i].linkAnd,
            scheme_ios: templateButtons[i].linkIos,
            url_mobile: templateButtons[i].linkMo,
            url_pc: templateButtons[i].linkPc,
            chat_extra: templateButtons[i].chat_extra,
            chat_event: templateButtons[i].chat_event,
            plugin_id: templateButtons[i].pluginId
          });
        }
      }
      return atButtons;
    },

    /**
     * 버튼의 link url 정보 리턴
     */
    getBtnUrls(btn) {
      let urls = [];
      if (btn.url_mobile) urls.push(btn.url_mobile);
      if (btn.url_pc) urls.push(btn.url_pc);
      if (btn.scheme_android) urls.push(btn.scheme_android);
      if (btn.scheme_ios) urls.push(btn.scheme_ios);
      if (btn.plugin_id) urls.push(btn.plugin_id);
      return urls.length > 0 ? urls.join("<br />") : null;
    },

    /**
     * ISO Date 타입 리턴
     */
    getDateTwoDigit(month, minute) {
      const date = new Date();
      const twoDigit = time => ("0" + time).slice(-2);
      if (minute && typeof minute == "number") {
        date.setMinutes(date.getMinutes() + minute);
      }
      let newYear = date.getFullYear();
      let newMonth = date.getMonth() + month;
      if (newMonth > 12) {
        newMonth -= 12;
        newYear++;
      }

      return (
        // date.getFullYear() +
        newYear +
        "-" +
        // twoDigit(date.getMonth() + month) +
        twoDigit(newMonth) +
        "-" +
        twoDigit(date.getDate()) +
        "T" +
        twoDigit(date.getHours()) +
        ":" +
        twoDigit(date.getMinutes())
      );
    },

    getTotalReceiversLength() {
      return this.receivers.length + this.excelReceivers.length;
    },
    /**
     * 아이템 리스트/ 요약정보 발송 형태로 변환 및 반환
     */
    setupItemList(objs) {
      let list = [];
      for (let i = 0, length = objs.list.length; i < length; i++) {
        list.push({
          title: objs.list[i].title,
          description: objs.list[i].description
        });
      }
      this.itemLists.list = list;
      let summary = {};
      if (objs.summary != null) {
        summary.title = objs.summary.title;
        summary.description = objs.summary.description;
      }
      this.itemLists.summary = summary;
    },
    /**
     * 버튼과 바로연결 정보를 발송 형태로 변환 및 반환
     */
    setupButtonQuickReply(objs) {
      let returns = [];
      for (let i = 0, length = objs.length; i < length; i++) {
        returns.push({
          name: objs[i].name,
          type: objs[i].linkType,
          scheme_android: objs[i].linkAnd,
          scheme_ios: objs[i].linkIos,
          url_mobile: objs[i].linkMo,
          url_pc: objs[i].linkPc,
          chat_extra: objs[i].chat_extra,
          chat_event: objs[i].chat_event,
          plugin_id: objs[i].pluginId
        });
      }
      return returns;
    },
    /**
     * 키 이벤트로 오직 숫자만 입력되도록 처리
     */
    onlyNumber() {
      this.baseInfo.sender_no = this._onlyNumber(this.baseInfo.sender_no);
    },
    /**
     * 알림 메시지 생성
     * @param type, message
     */
    notify(type = "default", message) {
      const notiMsg = message ? message : "일치하는 계정 정보가 없습니다.";
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type
      });
    }
  },
  computed: {
    existExcels() {
      return this.excels && this.excels[0] && this.excels.length > 0;
    },
    talkLength() {
      return this.baseInfo.message.length;
    },
    smsLength() {
      return this._eucKrLength(this.baseInfo.sms_message);
    },
    smsMaxLength() {
      const smsType = this.baseInfo.message_type.endsWith("M")
        ? this.baseInfo.message_type
        : this.baseInfo.sms_type;
      return smsType === "SM" ? 90 : 2000;
    },
    contractedMessageTypes() {
      return MESSAGE_TYPES.filter(type => {
        if ("AT" === type.value && "Y" === this.clientInfo.alimtalkYn) {
          return true;
        } else if (
          "FT" === type.value &&
          "Y" === this.clientInfo.friendstalkYn
        ) {
          return true;
        } else if ("SM" === type.value && "Y" === this.clientInfo.smsYn) {
          return true;
        } else if ("MM" === type.value && "Y" === this.clientInfo.mmsYn) {
          return true;
        } else if ("LM" === type.value && "Y" === this.clientInfo.lmsYn) {
          return true;
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    "baseInfo.message_type": function(val) {
      this.ad = false;
      this.reservedCd = "I";
      this.substitution = false;

      switch (val) {
        case "AT":
          if (this.templates.length > 0) {
            this.template = this.templates[0];
            this.baseInfo.message = this.template.templateContent;
          } else {
            this.template = {};
            this.baseInfo.message = "";
            this.templateImageData = null;
          }
          this.maxLength = 1000;
          this.ftButtons = [];
          this.image = [];
          this.images = [];
          break;
        case "FT":
          this.template = {};
          this.baseInfo.message = "";
          this.maxLength = 1000;
          this.ftButtons = [];
          this.images = [];
          this.templateImageData = null;
          break;
        case "SM":
          //sms 템플릿 정보 조회
          this.selectSmsTemplates();
          this.template = {};
          this.baseInfo.message = "";
          this.maxLength = 1000;
          this.ftButtons = [];
          this.image = [];
          this.images = [];
          this.templateImageData = null;
          break;
        case "LM":
          //sms 템플릿 정보 조회
          this.selectSmsTemplates();
          this.template = {};
          this.baseInfo.message = "";
          this.maxLength = 1000;
          this.ftButtons = [];
          this.image = [];
          this.images = [];
          this.templateImageData = null;
          break;
        case "MM":
          //sms 템플릿 정보 조회
          this.selectSmsTemplates();
          this.template = {};
          this.baseInfo.message = "";
          this.maxLength = 1000;
          this.ftButtons = [];
          this.image = [];
          this.templateImageData = null;
          break;
      }
    },
    "baseInfo.sms_message": function(val, old) {
      this.limitSmsMessage();
    },
    template: function(val) {
      // 버튼 json 정보 배열 형태로 변환 및 세팅
      if (val.buttonsJson != null && val.buttonsJson !== "") {
        const templateButtons = JSON.parse(val.buttonsJson);
        if (templateButtons && templateButtons.length > 0) {
          this.atButtons = this.setupButtonQuickReply(templateButtons);
        } else {
          this.atButtons = [];
        }
      } else {
        this.atButtons = [];
      }

      // 바로연결 json 정보 배열 형태로 변환 및 세팅
      if (val.quickRepliesJson != null && val.quickRepliesJson !== "") {
        const templateQuickReplies = JSON.parse(val.quickRepliesJson);
        if (templateQuickReplies && templateQuickReplies.length > 0) {
          this.quickReplies = this.setupButtonQuickReply(templateQuickReplies);
        } else {
          this.quickReplies = [];
        }
      } else {
        this.quickReplies = [];
      }

      // 이미지 템플릿 데이터 정보 조회;
      this.templateImageData = null;
      if (
        val.templateEmphasizeType === "IMAGE" ||
        val.templateEmphasizeType === "ITEM_LIST"
      ) {
        if (val.templateImageUrl) {
          this.$axios
            .get(`${USER_API_PREFIX}/template/image/url`, {
              params: {
                templateImageUrl: val.templateImageUrl,
                templateImageName: val.templateImageName
              }
            })
            .then(res => {
              this.templateImageData = res.data.result.imageData;
            });
        }
      }

      if (
        val.templateHighlightJson != null &&
        val.templateHighlightJson !== ""
      ) {
        const templateHighlightJson = JSON.parse(val.templateHighlightJson);
        if (templateHighlightJson) {
          this.itemHighlight.title = templateHighlightJson.title;
          this.itemHighlight.description = templateHighlightJson.description;
          this.itemHighlight.imageUrl = templateHighlightJson.imageUrl;
          this.itemHighlight.imageName = templateHighlightJson.imageName;
        } else {
          this.itemHighlight = {};
        }
      } else {
        this.itemHighlight = {};
      }

      if (val.templateItemJson != null && val.templateItemJson !== "") {
        const templateItemJson = JSON.parse(val.templateItemJson);
        if (templateItemJson && templateItemJson.list.length > 0) {
          this.setupItemList(templateItemJson);
        } else {
          this.itemLists = {};
        }
      } else {
        this.itemLists = {};
      }
    },
    reservedCd: function(val) {
      if (val) {
        this.reservationDate =
          this.reservedCd === "R" ? this.getDateTwoDigit(1, 10) : null;
      }
    },
    substitution: function(val) {
      if (val) {
        if (this.baseInfo.message_type === "FT") {
          this.baseInfo.sms_message = this.baseInfo.message;
          this.ad = true;
        } else {
          this.baseInfo.sms_message = this.template.templateContent;
          this.ad = false;
        }
        this.baseInfo.sms_type = this.smsTypes[0].value;
      } else {
        this.baseInfo.sms_type = null;
        this.ad = false;
      }
    },
    ad: function(val) {
      const ad = "(광고) ";
      if (val) {
        this.baseInfo.sms_message = this.baseInfo.sms_message.startsWith(ad)
          ? this.baseInfo.sms_message
          : ad + this.baseInfo.sms_message;
      } else {
        this.baseInfo.sms_message = this.baseInfo.sms_message.startsWith(ad)
          ? this.baseInfo.sms_message.replace(ad, "")
          : this.baseInfo.sms_message;
      }
    },
    /*
     * 친구톡 이미지 추가에 따른 메시지 자릿수 변경 진행
     * 이미지 추가 -> 400자로 변경
     * 이미지 없음 -> 1000자로 변경
     */
    image: function(val) {
      if (val.length > 0) {
        this.maxLength = 400;
      } else {
        this.maxLength = 1000;
      }
    },
    /*
     * 친구톡 와이드 이미지 여부에 따른 메시지 자릿수 변경 진행
     */
    wide: function(isWide) {
      if (this.baseInfo.message_type === "FT") {
        /* 와이드 말풍선 타입
            기본적으로 텍스트 타입과 동일하나 attachment 필드에 이미지를 추가하여 발송 가능.
            반드시 내부에 업로드 한 이미지를 발송해야 함.
            이미지 타입의 경우 텍스트 메시지 + 링크 버튼(1개) + 이미지 발송이 가능.
            텍스트 문구는 76자로 제한.
          */
        if (isWide) {
          this.maxLength = 76;
        } else {
          /* 이미지 타입
            기본적으로 텍스트 타입과 동일하나 attachment 필드에 이미지를 추가하여 발송 가능.
            반드시 내부에 업로드 한 이미지를 발송해야 함.
            이미지 타입의 경우 텍스트 메시지 + 링크 버튼 + 이미지 발송이 가능.
            텍스트 문구는 400자로 제한.
          */
          if (this.image.length > 0) {
            this.maxLength = 400;

            /* 텍스트 타입
            텍스트 타입의 경우 텍스트 메시지 + 링크 버튼 발송이 가능합니다.
            텍스트 문구는 1000자로 제한됩니다.
          */
          } else {
            this.maxLength = 1000;
          }
        }
      }
    },
    /**
     * 친구톡 이미지 첨부 방법 변경
     * @param value
     */
    ftNewImageYn: function(value) {
      if (value === "Y") {
        this.kkoImgUrl = null;
      } else {
        this.image = [];
      }
    }
  }
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
.dz-preview-multiple-scroll {
  padding-bottom: 0;
  border-bottom: 0;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 0px !important;
}
.dz-custom-li-list-name {
  overflow: hidden;
  width: calc(100% - 150px);
}
.txt-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overflow-y-auto {
  overflow-x: hidden;
  overflow-y: auto;
}
.el-form-item {
  margin-bottom: 0 !important;
}
.substitution-text {
  font-size: 0.7rem;
  opacity: 0.8;
  cursor: default;
}
</style>
