<template>
  <modal type="mini" :modalClasses="modalClasses" size="sm" body-classes="pt-0 pb-0" footerClasses="pt-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">미리보기</h6>

    <template>
      <card class="h-100 mh-100 mb-0" headerClasses="bg-kakao pt-2 pb-2" bodyClasses="message-height">
        <template v-if="baseInfo.message_type === 'AT'" slot="header">
          <h5 class="d-inline">알림톡 도착</h5>
        </template>
        <div v-show="['AT', 'FT', 'MM'].includes(baseInfo.message_type) && viewImages.length > 0" class="form-row mb-1">
          <template v-for="(option, index) in viewImages">
            <img :src="option" width="100%"/>
          </template>
        </div>

        <!-- 문자 제목 영역 -->
        <div v-show="['LM', 'MM'].includes(baseInfo.message_type)" class="form-row mb-1">
          <div v-if="baseInfo.title" class="col-md-12 font-weight-bold pb-2 mb-3" style="border-bottom: 1px solid #d3d3d3;" v-html="baseInfo.title"></div>
        </div>

        <div class="form-row mb-3">
          <!-- 강조타이틀 영역 -->
          <div v-if="template.templateEmphasizeType === 'TEXT'" style="width:100%;">
            <div style="display: block; width: 100%"><small style="color: #a9a9a9">{{ template.templateSubtitle }}</small></div>
            <p class="font-weight-bold mb-2 pb-3" style="border-bottom: 1px solid #d3d3d3; width: 100%;">{{ template.templateTitle }}</p>
          </div>
          <!-- 메시지 영역 -->
          <div class="col-md-12 mb-2" style="white-space: pre-wrap; font-size: 0.9rem;">{{message}}</div>
          <div v-if="template.templateExtra" class="col-md-12 mb-2" style="white-space: pre-wrap; font-size: 0.9rem; color: #a9a9a9;">{{template.templateExtra}}</div>
          <div v-if="template.templateAd" class="col-md-12" style="white-space: pre-wrap; font-size: 0.9rem; color: #a9a9a9;">{{template.templateAd}}</div>
        </div>

        <!-- 버튼 영역 -->
        <template v-if="baseInfo.message_type === 'AT' && atButtons.length > 0">
          <div class="form-row mb-1" v-for="(option, index) in atButtons">
            <base-button block buttonClasses="kakao-button-bg-color" type="Secondary" size="sm">{{option.name}}</base-button>
          </div>
        </template>
        <template v-if="baseInfo.message_type === 'FT' && ftButtons.length > 0">
          <div class="form-row mb-1" v-for="(option, index) in ftButtons">
            <base-button block buttonClasses="kakao-button-bg-color" type="Secondary" size="sm">{{option.name}}</base-button>
          </div>
        </template>
        <!-- 바로연결 영역 -->
        <div class="mt-0 mt-3 text-center" v-if="baseInfo.message_type === 'AT' && quickReplies.length > 0">
          <template>
            <base-button v-for="(option, index) in quickReplies" buttonClasses="badge badge-pill badge-secondary" size="sm">{{option.name}}</base-button>
          </template>
        </div>
      </card>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>
  </modal>
</template>

<script>
  import TemplateForm from "../Template/TemplateForm";
  export default {
    name: 'PreViewLayer',
    mixins: [],
    components: {
      TemplateForm
    },
    props : {
      baseInfo: {
        type : Object,
        default: () => ({})
      },
      template: {
        type : Object,
        default: () => ({})
      },
      atButtons: {
        type : Array,
        default: () => ([])
      },
      ftButtons: {
        type : Array,
        default: () => ([])
      },
      quickReplies: {
        type : Array,
        default: () => ([])
      },
      receiver : {
        type : Object,
        default: () => ({
          headers : "",
          values : ""
        })
      },
      templateImageData:  {
        type : String,
        default: null
      },
      image: {
        type : Array,
        default: () => ([])
      },
      images : {
        type : Array,
        default: () => ([])
      },
      wide : {
        type : Boolean,
        default: false
      }
    },
    data() {
      return {
        message : '',
        modalClasses : '',
        preViewModalClasses : {
          'AT' : 'kakao-alim-modal',
          'FT' : this.wide ? 'kakao-friend-wide-modal' : 'kakao-friend-modal',
          'SM' : 'kakao-sms-modal',
        },
        buttonClasses : '',
        viewImages:[]
      }
    },
    created() {
      this.viewImages = [];
      this.modalClasses = this.preViewModalClasses[this.baseInfo.message_type];
      const message = this.baseInfo.message_type.endsWith("T") ? this.baseInfo.message : this.baseInfo.sms_message;
      this.message = this.headerMapper(message, this.receiver.headers, this.receiver.values);
      const images = this.baseInfo.message_type === 'MM' ? this.images : this.image;

      for (let i=0, length = images.length; i < length; i++) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(images[i]);
        fileReader.onloadend = () => {
          if(fileReader.result) {
            this.viewImages.push(fileReader.result);
          }
        }
      }
      // 알림톡 이미지 데이터 세팅
      if (this.templateImageData) {
        this.viewImages.push(this.templateImageData);
      }

      if (this.baseInfo.button && this.baseInfo.button.length > 0) {
        this.buttonClasses = `kakao-button-bg-color button-width-auto-${this.baseInfo.button.length}`;
        this.baseInfo.button = this.baseInfo.button && this.baseInfo.button.length > 0 ? this.baseInfo.button.sort((a, b) => a.order - b.order) : [];
      }

    },
    methods: {
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 헤더 맵퍼
       * 메시지를 수신자별 정의로 변경
       * @param message, headersStr, valuesStr
       */
      headerMapper(message, headersStr, valuesStr) {
        let result = message;
        if (headersStr && valuesStr) {
          const headers = headersStr.split('|');
          const values = valuesStr.split('|');
          for (let i=0, length = headers.length; i < length; i++) {
            result = result.replace("#{" + headers[i].trim() + "}", values[i].trim());
          }
        }
        return result;
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    }
  };
</script>

<style>
  .kakao-alim-modal {
    max-width: 345px !important;
  }
  .kakao-friend-modal {
    max-width: 375px !important;
  }
  .kakao-friend-wide-modal {
    max-width: 450px !important;
  }
  .sms-model {
    max-width: 400px !important;
  }
  .min-height-150 {
    min-height: 150px !important;
  }
  .kakao-button-bg-color {
    background-color:  #F2F2F2 !important;
  }
  .button-width-auto-1 {
    width : 100%;
  }
  .button-width-auto-2 {
    width : calc(50% - 8px);
  }
  .button-width-auto-3 {
    width : calc(33.33% - 8px);
  }
  .button-width-auto-4 {
    width : calc(25% - 8px);
  }
  .button-width-auto-5 {
    width : calc(20% - 8px);
  }
  .message-height {
    max-height: 600px;
    overflow-y: auto;
  }
</style>
