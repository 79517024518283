<template>
  <modal modalClasses="edit-button-modal" size="lg" body-classes="pt-0 pb-0" footerClasses="pt-0" @close="closeLayer">
    <h6 slot="header" class="modal-title">버튼 등록</h6>

    <template>
      <div class="form-row mb-1">
        <div class="col-md-12 p-0">
          <div class="float-xl-right">
            <base-button type="primary" size="sm" class="mb-2" @click="addButton">추가</base-button>
          </div>
        </div>
      </div>
      <div class="form-row mb-1">
        <el-table header-row-class-name="thead-light" header-cell-class-name="p-1" cell-class-name="p-2" :data="buttons" :height="400" :max-height="400" empty-text="버튼을 추가해 주세요." border>
          <el-table-column label="순서" prop="receiverNo" min-width="30" fixed header-align="center" align="center">
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column label="버튼타입" prop="receiverNo" min-width="90" align="center">
            <template slot-scope="scope">
              <el-form>
                <el-form-item :error="_getError('버튼타입')">
                  <base-input v-model="scope.row.type" name="버튼타입" additionalClasses="mb-0" v-validate="'required'">
                    <el-select class="select-danger" placeholder="Single Select" filterable v-model="scope.row.type">
                      <el-option v-for="(option, index) in friendButtonTypes" class="select-danger" :value="option.value" :label="option.label" :key="index"/>
                    </el-select>
                  </base-input>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="버튼명" prop="" min-width="140" align="center">
            <template slot-scope="scope">
              <el-form>
                <el-form-item :error="_getError(`버튼명${scope.$index+1}`)">
                  <el-input :maxlength="14" size="small" :name="`버튼명${scope.$index+1}`"  v-validate="'required|max:14'" v-model="scope.row.name" :autosize="true" placeholder="버튼명 입력 (14자 이내)"/>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="버튼링크" prop="" align="center" header-align="center">
            <el-table-column label="링크타입" prop="" min-width="50" align="center" header-align="center" :resizable="false">
              <template slot-scope="scope">
                <template v-if="scope.row.type === 'AL'">
                  <base-button type="default" size="sm" class="button-link-type-height p-0" block outline disabled>Android</base-button>
                  <base-button type="default" size="sm" class="button-link-type-height p-0" block outline disabled>IOS</base-button>
                </template>
                <template v-if="scope.row.type.endsWith('L')">
                  <base-button type="default" size="sm" class="button-link-type-height p-0" block outline disabled>Mobile</base-button>
                  <base-button type="default" size="sm" class="button-link-type-height p-0" block outline disabled>PC (선택)</base-button>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="입력" prop="" min-width="225" align="center" header-align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.type === 'AL'">
                  <el-form>
                    <el-form-item :error="_getError('Android, IOS, Mobile 중 2개')">
                      <el-input size="small" data-vv-name="Android, IOS , Mobile 중 2개" v-validate="{required:requiredLinks(scope.row)}" v-model="scope.row.scheme_android" @blur="removeBlank(scope.row, 'scheme_android')" :autosize="true" placeholder="scheme_android(Android, iOS, Mobile 중 2가지 이상 입력 필수)"></el-input>
                    </el-form-item>
                  </el-form>
                  <el-form>
                    <el-form-item :error="_getError('Android, IOS, Mobile 중 2개')">
                      <el-input size="small" data-vv-name="Android, IOS, Mobile 중 2개" v-validate="{required:requiredLinks(scope.row)}" v-model="scope.row.scheme_ios" @blur="removeBlank(scope.row, 'scheme_ios')" :autosize="true" placeholder="scheme_ios(Android, iOS, Mobile 중 2가지 이상 입력 필수)"></el-input>
                    </el-form-item>
                  </el-form>
                </template>
                <template v-if="scope.row.type.endsWith('L')">
                  <el-form>
                    <el-form-item :error="_getError('Android, IOS, Mobile 중 2개')">
                      <el-input size="small" data-vv-name="Android, IOS, Mobile 중 2개" v-validate="{required:requiredLinks(scope.row)}" v-model="scope.row.url_mobile" @blur="removeBlank(scope.row, 'url_mobile')" :autosize="true" placeholder="http:// 또는 https:// 가 포함된 URL"></el-input>
                    </el-form-item>
                  </el-form>
                  <el-input size="small" :name="`Pc 링크${scope.$index+1}`" v-model="scope.row.url_pc" @blur="removeBlank(scope.row, 'url_pc')" :autosize="true" placeholder="http:// 또는 https:// 가 포함된 URL"></el-input>
                </template>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="정렬" prop="" min-width="60" :resizable="false" fixed="right" header-align="center" align="center" >
            <template slot-scope="scope">
              <el-form>
                <el-form-item>
                  <el-tooltip content="위" placement="top">
                    <base-button type="success" size="sm" class="btn-icon-only" @click.native="buttons.splice(scope.$index - 1, 0, buttons.splice(scope.$index, 1)[0])">
                      <i class="ni ni-bold-up"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="아래" placement="top">
                    <base-button type="success" size="sm" class="btn-icon-only" @click.native="buttons.splice(scope.$index + 1, 0, buttons.splice(scope.$index, 1)[0])">
                      <i class="ni ni-bold-down"></i>
                    </base-button>
                  </el-tooltip>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="삭제" prop="" min-width="35" :resizable="false" fixed="right" header-align="center" align="center" >
            <template slot-scope="scope">
              <el-form>
                <el-form-item>
                  <el-tooltip content="삭제" placement="top">
                    <base-button type="danger" size="sm" class="btn-icon-only" @click="removeButton(scope.$index)">
                      <i class="ni ni-fat-delete"></i>
                    </base-button>
                  </el-tooltip>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="closeLayer">닫기</base-button>
    </template>
  </modal>
</template>

<script>
  import { Table, TableColumn, Select, Option, Form, FormItem, InputNumber } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { FRIEND_BUTTON_TYPES } from '@/shared/util/const';

  export default {
    name: 'EditButtonsLayer',
    inject: ['$validator'],
    mixins: [
      commJsUtilMixin, commValidatorMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [InputNumber.name]: InputNumber,
    },
    props : {
      baseInfo: {
        type : Object,
        default: () => ({})
      },
      buttons: {
        type : Array,
        default: () => ([])
      },
    },
    data() {
      return {
        button : {},
        friendButtonTypes : FRIEND_BUTTON_TYPES,
      }
    },
    created() {
    },
    methods: {
      /**
       * 버튼링크 검증
       */
      requiredLinks(row) {
        let required = 0;
        if(row.type === 'WL') {
          return row.url_mobile && row.url_mobile.length > 0;
        } else if(row.type === 'AL') {
          return (row.url_mobile && row.url_mobile.length > 0 ? 1 : 0) + (row.scheme_android && row.scheme_android.length > 0 ? 1 : 0) + (row.scheme_ios && row.scheme_ios.length > 0 ? 1 : 0 > 0 ? 1 : 0) < 2;
        }
      },
      /**
       * 버튼추가
       */
      addButton() {
        if(this.buttons.length >= 5) {
          this.notify('warning', '버튼은 5개까지 등록이 가능합니다.');
        } else {
          this.buttons.push({'ordering' : this.buttons.length+1, 'name' : '', 'type' : this.friendButtonTypes[0].value, 'linkTypeName' : '', 'url_mobile' : '', 'scheme_ios' : '', 'scheme_android' : ''});
        }
      },
      /**
       * 버튼삭제
       */
      removeButton(index) {
        this.buttons.splice(index, 1);
      },
      /**
       * 레이어 닫기 이벤트 실행
       */
      closeLayer() {
        this.$emit('close');
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      /**
       * url 빈값 제거
       * @param row
       * @param key
       */
      removeBlank(row, key){
        row[key] = row[key].trim();
      }
    }
  };
</script>

<style>
  .edit-button-modal {
    max-width: 1100px;
  }
  .button-link-type-height {
    height: 32px;
  }
</style>
